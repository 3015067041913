.short-day {
  font-weight: bold;
}

.dates {
  display: flex;
}

.tail-datetime-calendar {
  width: 490px;
  height: auto;
  margin: 15px;
  padding: 0;
  display: block;
  overflow: hidden;
  position: absolute;
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  border: 1px #20A6A3 solid;
  border-radius: 10px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
}

@media only screen and (max-width: 768px) {
  .tail-datetime-calendar {
    width: 245px;
    height: auto;
    margin: 15px;
    padding: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    border-collapse: separate;
    font-family: "Open Sans", Calibri, Arial, sans-serif;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    border: 1px #20A6A3 solid;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  }
}

.tail-datetime-calendar:after {
  clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden;
}

.tail-datetime-calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}

.tail-datetime-calendar .calendar-navi {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  color: #20A6A3;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
}

.tail-datetime-calendar .calendar-navi span {
  color: #20A6A3;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
  background-repeat: no-repeat;
  background-position: center center;
}

.tail-datetime-calendar .calendar-navi span:first-child,
.tail-datetime-calendar .calendar-navi span:last-child {
  width: 35px;
  padding: 0 0 5px 0;
  font-size: 22px;
}

.tail-datetime-calendar .calendar-navi span.button-prev {
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
  filter: invert(49%) sepia(100%) saturate(322%) hue-rotate(130deg) brightness(90%) contrast(96%);
}
.tail-datetime-calendar .calendar-navi span.button-next {
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
  filter: invert(49%) sepia(100%) saturate(322%) hue-rotate(130deg) brightness(90%) contrast(96%);
}
.tail-datetime-calendar .calendar-navi span.button-check {
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
  filter: invert(49%) sepia(100%) saturate(322%) hue-rotate(130deg) brightness(90%) contrast(96%);
}
.tail-datetime-calendar .calendar-navi span.button-close {
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4yNWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
  filter: invert(49%) sepia(100%) saturate(322%) hue-rotate(130deg) brightness(90%) contrast(96%);
}

.tail-datetime-calendar .calendar-date {
  margin-right: 20px;
  padding: 0;
  display: block;
}

@media only screen and (max-width: 768px) {
  .tail-datetime-calendar .calendar-date {
    margin-right: 0px;
    padding: 0;
    display: block;
  }
}

.tail-datetime-calendar .calendar-date .calendar-label {
  color: #20A6A3;
  font-weight: bold;
}

.tail-datetime-calendar .calendar-date table {
  width: 100%;
  border-spacing: 0 3px;
}

.tail-datetime-calendar .calendar-date table thead tr > *,
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #20A6A3;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  line-height: 35px;
  border: 0;
  background-color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: gray;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
}

.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: #efefef;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
  color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
  border-color: #20A6A3;
  background-color: #20A6A3;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.tail-datetime-calendar .calendar-date2 {
  margin-left: 20px;
  display: block;
  text-align: center;
}

.tail-datetime-calendar .calendar-date2 .calendar-label {
  color: #20A6A3;
  font-weight: bold;
}

.tail-datetime-calendar .calendar-date2 table {
  width: 100%;
  border-spacing: 0 3px;
}
.tail-datetime-calendar .calendar-date2 table thead tr > *,
.tail-datetime-calendar .calendar-date2 table tbody tr > * {
  color: #20A6A3;
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 12px;
  text-align: center;
  line-height: 35px;
  border: 0;
  background-color: #fff;
}
.tail-datetime-calendar .calendar-date2 table tbody tr > * {
  color: gray;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
}
.tail-datetime-calendar .calendar-date2 table tbody tr > * > span {
  color: inherit;
  z-index: 10;
  position: relative;
}

.tail-datetime-calendar .calendar-date2 table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: #efefef;
}
.tail-datetime-calendar .calendar-date2 table tbody tr > *.empty:hover:after,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail-datetime-calendar .calendar-date2 table tbody tr > *.current span {
  color: #fff;
}
.tail-datetime-calendar .calendar-date2 table tbody tr > *.current:after {
  border-color: #20A6A3;
  background-color: #20A6A3;
}

@media only screen and (max-width: 768px) {
  .tail-datetime-calendar .calendar-date2,
  #root > div > div.calendar-navi > span.calendar-label2,
  .tail-datetime-calendar .calendar-date2 .calendar-label {
    display: none;
  }
}

.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail-datetime-calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #20A6A3;
}
.tail-datetime-calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}

.calnedar-label {
  text-align: center;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.between > div,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.between > div{
  color: #20A6A3;
  border-top: 1px solid #20A6A3;
  border-bottom: 1px solid #20A6A3;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.between:last-child > div,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.between:last-child > div {
  color: #20A6A3;
  border-top: 1px solid #20A6A3;
  border-bottom: 1px solid #20A6A3;
  border-right: 1px solid #20A6A3;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.between:first-child > div,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.between:first-child > div{
  color: #20A6A3;
  border-top: 1px solid #20A6A3;
  border-bottom: 1px solid #20A6A3;
  border-left: 1px solid #20A6A3;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.selectedF,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.selectedF {
  color: #20A6A3;
  border-top: 1px solid #20A6A3;
  border-bottom: 1px solid #20A6A3;
  border-left: 1px solid #20A6A3;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.selectedS,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.selectedS {
  color: #20A6A3;
  border-top: 1px solid #20A6A3;
  border-bottom: 1px solid #20A6A3;
  border-right: 1px solid #20A6A3;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.selectedS > div,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.selectedS > div,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.selectedF > div,
.tail-datetime-calendar .calendar-date table tbody tr > *.selectedF > div {
  width: 100%;
  color: #fff;
  border-radius: 50%;
  background-color: #20A6A3;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.selected,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.selected {
  color: #fff;
  border-radius: 100px;
  background-color: #20A6A3;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.PastDay,
.tail-datetime-calendar .calendar-date2 table tbody tr > *.PastDay {
  cursor: not-allowed;
  opacity: 50%;
}
